* {
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 12px !important;
}

.logo{
  border-radius: 0px !important;
}
.overview-img {
  width: 34%;
  float: right;
}

.border-radius-zero {
  border-radius: 0px !important;
}